<template>
  <div>
    <div
      v-if="!isMobile"
      class="grid grid-cols-1 lg:p-0 sm:grid-cols-1 md:grid-cols-2  gap-5"
      :class="[
        propertyView ? 'lg:grid-cols-2' : 'lg:grid-cols-3',
        propertyView ? 'xl:grid-cols-3' : 'xl:grid-cols-4',
      ]
      "
    >
      <CreateHzbaCard
        v-if="propertyView && featureFlags?.createSurvey"
        @click="openAufnahmenModal()"
      />
      <hzba-card
        v-for="ba in hzbas"
        :key="ba.id"
        :ba="ba"
        :ui-mode="uiMode"
      />
    </div>
    <div v-else>
      <swiper
        v-if="hzbas.length > 1"
        class="custom-swiper"
        :class="{ 'expand-swiper-to-left': prevButtonDisabled }"
        :modules="modules"
        :slides-per-view="1"
        :space-between="24"
        navigation
        :pagination="false"
        :scrollbar="{ draggable: true }"
        :virtual="true"
        :breakpoints="{
          640: { slidesPerView: 2, spaceBetween: 20 },
          1024: { slidesPerView: 3, spaceBetween: 30 }
        }"
        @transition-end="disablePrevButton($event.activeIndex)"
      >
        <swiper-slide
          v-for="ba, index in hzbas"
          :key="ba.id"
          :virtual-index="ba.id"
        >
          <hzba-card 
            :with-add-button="index === 0"
            :ba="ba"
            :ui-mode="uiMode"
            @create-survey="openAufnahmenModal"
          />
        </swiper-slide>
      </swiper>
      <hzba-card 
        v-if="hzbas.length === 1"
        :with-add-button="true"
        :ba="hzbas[0]"
        :ui-mode="uiMode"
        @create-survey="openAufnahmenModal"
      />
    </div>
  </div>
</template>

<script lang="ts">
// import Swiper core and required modules
import { A11y, Navigation, Pagination, Scrollbar, Virtual } from 'swiper/modules';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import CreateHzbaCard from "@/components/hzba/CreateHzbaCard.vue";
import CreateSurveyModal from "@/components/v2/General/CreateSurveyModal.vue";
import { useStore } from "@/composables/useTypedStore";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import {
    IonBadge,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonChip,
    IonLabel,
    modalController
} from "@ionic/vue";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { computed, defineComponent, PropType, ref } from "vue";
import HzbaCard from "./HzbaCard.vue";

export default defineComponent({
  name: "HzbaList",
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonButton,
    IonLabel,
    IonBadge,
    IonChip,
    HzbaCard,
    CreateHzbaCard,
    Swiper, SwiperSlide
  },
  props: {
    hzbas: {
      type: Object as PropType<Bestandsaufnahme[]>,
      required: true,
    },
    propertyView: {
      type: Boolean,
      default: false
    },
    property: {
      type: Object,
      required: false
    },
    templateIdentifier: {
      type: String,
      required: false
    }
  },
  setup() {
    const { user } = useUser();
    const store = useStore();

    const prevButtonDisabled = ref(true);

    const isMobile = computed(() => {
      return store.getters["app/isMobile"];
    });
    const uiMode = computed(() => {
      return isMobile.value ? "compact" : "normal";
    });

    const disablePrevButton = (index: number) => {
      prevButtonDisabled.value = index === 0;
    };

    return {
        featureFlags: computed(() => user.value?.organisation?.featureFlags),
        uiMode,
        modules: [Navigation, Pagination, Scrollbar, A11y, Virtual],
        isMobile,
        prevButtonDisabled,
        disablePrevButton
    }
  },
  methods: {
    async openAufnahmenModal() {
      const modal = await modalController.create({
        component: CreateSurveyModal,
        cssClass: 'v2Modal',
        componentProps: {
          property: this.property,
          templateIdentifier: this.templateIdentifier
        },
        canDismiss: true,
        //@ts-ignore
        presentingElement: this.$root.$refs.ionRouter.ionRouterOutlet
      });
      return modal.present();
    },
  }
});
</script>

<style scoped>

.forceOneCol {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.custom-swiper {
    transition: 0.2s padding-left ease;
    padding: 16px 50px;
    width: 100%
}

.custom-swiper.expand-swiper-to-left {
    padding-left: 0px;
    transition: 0.3s padding-left ease;
}

:deep(.swiper-button-next),
:deep(.swiper-button-prev) {
    color: var(--primary);
    opacity: 0.8;
}

:deep(.swiper-button-next.swiper-button-disabled), :deep(.swiper-button-prev.swiper-button-disabled) {
    opacity: 0.3;
}

:deep(.swiper-button-prev) {
  transition: 0.3s left ease;
  left: var(--swiper-navigation-sides-offset, 10px);
}

:deep(.expand-swiper-to-left .swiper-button-prev) {
    left: -50px;
}

</style>


