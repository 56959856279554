import { SortTermActive } from "@/composables/Sort/SortTerms";
import { BestandsaufnahmeJson } from "@/models/ba/interfaces/IBestandsaufnahme";
import { ImmobilieStatus } from "@/models/immobilie/interfaces/IImmobilie";
import User from "@/models/user";
import { Model } from '@vuex-orm/core';
import Dokument from './dokument.model';
import ErweitertesMedium from './erweitertes-medium.model';
import StrapiMedia from './photo/strapi-media.model';
import { deleteDocumentFromObject } from "@/api/ModelApi";
import { Monitoring } from "@/utilities/monitoring";

export default class Immobilie extends Model {

  static entity = 'immobilie';

  static fields() {
    return {
      id: this.attr(null),
      name: this.attr(null),
      status: this.attr(null),
      eigentuemer: this.attr(null),
      strasse: this.attr(null),
      plz: this.attr(null),
      stadt: this.attr(null),
      stadtbezirk: this.attr(null),
      verwalters: this.attr(null),
      externeObjektNr: this.attr(null),
      technikzentraleBericht: this.attr(null),
      baujahr: this.attr(null),
      bilder: this.attr(null),
      vorschaubild: this.attr(null),
      videos: this.attr(null),
      drohnenbilder: this.attr(null),
      imageVideo: this.attr(null),
      imageVideoLink: this.attr(null),
      anlageschema: this.attr(null),
      geolocationLat: this.attr(null),
      geolocationLon: this.attr(null),
      grundrisss: this.attr(null),
      matterports: this.attr(null),
      dokuments: this.attr(null),
      updatedAt: this.attr(null),
      isDownloaded: this.attr(null),
      hasUnsavedChanges: this.attr(false),
      bestandsaufnahmes: this.attr(null),
      grundrissAnzeigen: this.attr(null),
      drohnenbilderAnzeigen: this.attr(null),
      matterportsAnzeigen: this.attr(null),
      managementSummaries: this.attr(null),
      otherDokuments: this.attr(null),
      notes: this.attr(null)
    };
  }


  id!: number;
  name!: string;
  status!: ImmobilieStatus;
  eigentuemer!: string;
  strasse!: string;
  plz!: string;
  stadt!: string;
  stadtbezirk!: string;
  verwalters!: User[];
  baujahr!: number;
  externeObjektNr!: string;
  technikzentraleBericht!: StrapiMedia;
  bilder!: StrapiMedia[];
  vorschaubild!: StrapiMedia;
  videos!: StrapiMedia[];
  drohnenbilder!: StrapiMedia[]
  imageVideo!: StrapiMedia;
  imageVideoLink!: string;
  anlageschema!: StrapiMedia;
  geolocationLat!: number;
  geolocationLon!: number;
  grundrisss!: ErweitertesMedium[];
  matterports!: ErweitertesMedium[];
  dokuments!: Dokument[];
  managementSummaries!: Dokument[];
  otherDokuments!: Dokument[];
  notes: any;
  bestandsaufnahmes!: BestandsaufnahmeJson[];
  updatedAt!: Date;
  isDownloaded!: boolean;
  hasUnsavedChanges!: boolean;
  grundrissAnzeigen!: boolean;
  drohnenbilderAnzeigen!: boolean;
  matterportsAnzeigen!: boolean;
}

export const immobilie = {
  state: {
    propertiesLoaded: false,
    page: 0,
    pageCount: undefined,
    searchResultsCount: null as number | null,
    totalSearchResultsCount: null as number | null,
    sortBy: { label: 'Leitungsanlage', fieldName: 'stadt', orderBy: 'asc'},
    searchTerm: undefined,
    statusFilter: [],
    personFilter: [],
    surveyDatumFilter: [],

    persistedPropertiesLoaded: false,
    persistedProperties: undefined,

    fallbackProperties: [],
  },
  actions: {
    setLoaded: ({ state }: { state: any}) => {
      state.propertiesLoaded = true;
    },
    setPage: ({ state }: { state: any}, page: number) => {
      state.page = page;
    },
    setPageCount: ({ state }: { state: any}, pageCount: number) => {
      state.pageCount = pageCount;
    },
    setSortBy: ({ state }: { state: any}, sortBy: SortTermActive) => {
      state.sortBy = sortBy;
    },
    setSearchTerm: ({ state }: { state: any}, searchTerm: string) => {
      state.searchTerm = searchTerm;
    },
    setStatusFilter: ({ state }: { state: any }, statusFilter: string) => {
      state.statusFilter = statusFilter;
    },
    setPersonFilter: ({ state }: { state: any }, personFilter: string) => {
      state.personFilter = personFilter;
    },
    setSurveyDatumFilter: ({ state }: { state: any }, surveyDatumFilter: string) => {
        state.surveyDatumFilter = surveyDatumFilter;
    },
  

    loadPersistedProperties: async ({ state }: { state: any}) => {
      const { immobilie } = await Immobilie.dispatch("$fetchFromLocal");
      return immobilie || [];

      // if (!state.persistedPropertiesLoaded) {
      //   const {immobilie} = await Immobilie.dispatch("$fetchFromLocal");
      //   await Immobilie.deleteAll();
      //   state.persistedProperties = immobilie;
      //   state.persistedPropertiesLoaded = true;
      //   return immobilie;
      // } else {
      //   return state.persistedProperties || [];
      // }
    },
    // addToPersistedProperties: async({ state }: { state: any}, immobilie: Immobilie) => {
    //   if (state.persistedPropertiesLoaded) {

    //     if (!state.persistedProperties) { 
    //       state.persistedProperties = [] 
    //     }

    //     const existingPersistedImmoIndex = state.persistedProperties.findIndex((el: Immobilie) => el.id === immobilie.id);

    //     if ( existingPersistedImmoIndex !== -1 ) {
    //       state.persistedProperties[existingPersistedImmoIndex] = immobilie;
    //     } else {
    //       state.persistedProperties.push(immobilie);
    //     }
      
    //   }
    // },
    // removeFromPersistedProperties: async({ state }: { state: any}, immobilie: Immobilie) => {
    //   if (state.persistedPropertiesLoaded && state.persistedProperties) {
    //     const index = state.persistedProperties.findIndex((el: Immobilie) => el.id === immobilie.id);
    //     if (index >= 0) {
    //       state.persistedProperties.splice(index, 1);
    //     } else {
    //       console.warn("Could not remove property from persisted properties, index is ", index);
    //       logger.warn(`Could not remove property from persisted properties, index is ${index}`);

    //     }
    //   }
    // },

    /**
     * When ba's gets loaded, we get information of properties within.
     * But we cannot store those properties to the store of the other properties as this would bring confusion to the pagination but also filter system.
     * That is why we add those properties to a separate storage.
     */
    addToFallbackProperties: async({ state }: { state: any}, immobilie: any) => {
      if (!immobilie) { return; }

      if (!state.fallbackProperties.find((el: any) => el.id === immobilie.id) ){
        state.fallbackProperties.push(immobilie);
      }
    },
    updateDokumentsField: async ({ state }: { state: any}, { id, newDocuments }: { id: number, newDocuments: any[] }) => {
      const object = Immobilie.find(id);
      if (object) {
        const objectJson = object.$toJson();
        objectJson.dokuments = objectJson.dokuments.filter((dok: any) => newDocuments.some(d => d.id === dok.id));
        if(object.isDownloaded) {
          await Immobilie.dispatch('$updateLocally', { data: objectJson });
        } else {
          await Immobilie.insertOrUpdate({ data: objectJson });
        }
        return true;
      }
      return false;
    },
    deleteDocument: async ({ dispatch }: { dispatch: any}, { documentId, objectId }: { documentId: number, objectId: number}) => {
      try {
        const response = await deleteDocumentFromObject(objectId, documentId);
        return await dispatch('updateDokumentsField', { id: objectId, newDocuments: response.dokuments });
      } catch (error: any) {
        Monitoring.withScope((scope) => {
          scope.setContext("Delete document from object", { documentId, objectId });
          Monitoring.error(error);
        });
        return false;
      }
    }
  },
  getters: {
    loaded: (state: any) => state.propertiesLoaded,
    page: (state: any) => state.page,
    pageCount: (state: any) => state.pageCount,
    sortBy: (state: any) => state.sortBy,
    searchTerm: (state: any) => state.searchTerm,
    statusFilter: (state: any) => state.statusFilter,
    personFilter: (state: any) => state.personFilter,
    surveyDatumFilter: (state: any) => state.surveyDatumFilter,
    persistedProperties: (state: any) => Immobilie.query().where('isDownloaded', true).get(),

    propMatchesFilter: () => (property: Immobilie, searchTerm: string) => {
    
      if (!property || !searchTerm) {
        return false;
      }
      const lowerCaseSearchTerm = searchTerm.toLowerCase();

      return (
        (property.externeObjektNr ? property.externeObjektNr.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
        (property.strasse ? property.strasse.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
        (property.stadt ? property.stadt.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
        (property.plz ? property.plz.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
        (property.name ? property.name.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
        (property.eigentuemer ? property.eigentuemer.toLowerCase().includes(lowerCaseSearchTerm) : false) ||
        (property.baujahr !== null && property.baujahr !== undefined ? property.baujahr.toString().toLowerCase().includes(lowerCaseSearchTerm) : false)
      );
    },    
    getFallbackProperties: ( state: any) => {
      return state.fallbackProperties;
    },
    getPropOrFallbackProp: (state: any) => (id: number) => {
      const prop = Immobilie.find(id)
      if (prop) { return prop }

      return state.fallbackProperties.find((el: any) => el.id === id);
    }
  }
}