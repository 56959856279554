<template>
  <span
    class="btn-secondary
            inline-flex
            items-center
            px-4
            py-1.5
            rounded-full
            text-sm
            font-medium
            text-blue-600
            survey-button"
  >
    <slot />
  </span>
</template>

<script
    setup
    lang="ts"
>

</script>


<style
    scoped
    lang="scss"
>
.survey-button {
  border: 1px solid var(--primary);
}

</style>