<template>
  <div class="createSurveyModal">
    <ion-content>
      <div class="createSurveyModalContent p-6">
        <div class="formHolder">
          <div class="header headline3 mb-5">
            {{ t(`${organisationPrefix}createSurvey.modalTitle`) }}
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t(`${organisationPrefix}createSurvey.type`) }}
            </div>
            <div class="value">
              <AppSelect
                v-model="body.type"
                :items="preparedTemplates"
                :initialValue="body.type"
                :placeholder="t('createSurvey.selectTemplate')"
              />
            </div>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t(`${organisationPrefix}createSurvey.object`) }}
            </div>
            <div class="value">
              <AppSelect
                v-model="body.property"
                :items="preparedProjects"
                :initialValue="body.property"
                :placeholder="t(`${organisationPrefix}createSurvey.selectObject`)"
              />
            </div>
          </div>
          <div
            v-if="canCreateUpdateObject && !body.property && featureFlags?.createObject"
            class="createObjectButton mb-6"
          >
            <AButton
              :btn-secondary="true"
              class="p-2"
              @click="handleCreateProperty()"
            >
              {{ t(`${organisationPrefix}createSurvey.createObject`) }}
            </AButton>
          </div>
          <div class="sectionItem mb-4">
            <div class="label">
              {{ t('createSurvey.date') }}
            </div>
            <div
              id="openDateTimeModal"
              class="value"
            >
              <ion-item>
                {{ new Date(body.date).toLocaleDateString('de-DE') }} {{ moment(body.date).format('hh:mm') }}
              </ion-item>
            </div>
          </div>
          <div class="sectionItem mb-16">
            <div class="label">
              {{ t('createSurvey.person') }}
            </div>
            <div class="value">
              <AppInput
                v-model="body.name"
                :readonly="true"
                :placeholder="t('createSurvey.typing')"
              />
            </div>
          </div>
        </div>
        <div class="buttonsHolder">
          <AButton
            :disabled="isButtonActive ? false : true"
            :btn-primary="true"
            class="px-16 py-6 text-lg"
            @click="sendData()"
          >
            {{ t(`${organisationPrefix}createSurvey.createRecord`) }}
          </AButton>
          <AButton
            :btn-secondary="true"
            class="px-16 py-6 text-lg"
            @click="modalController.dismiss()"
          >
            {{ routeName === "propertiesMap" ? t('createSurvey.backToMap') : t('createSurvey.backToOverview') }}
          </AButton>
        </div>
      </div>
    </ion-content>

    <ion-modal
      trigger="openDateTimeModal"
      size="auto"
      class="v2Modal dateTimeModal"
    >
      <ion-content>
        <div class="dateTimeContent p-6">
          <div class="dateTimeHolder pb-16">
            <AppDateTime
              v-model="body.date"
            />
          </div>
          <div class="buttonHolder">
            <AButton
              :btn-primary="true"
              class="px-16 py-6 text-lg"
              @click="modalController.dismiss()"
            >
              {{ t('createSurvey.ok') }}
            </AButton>
          </div>
        </div>
      </ion-content>
    </ion-modal>
  </div>
</template>

<script setup lang="ts">
import { getCache, setCache } from '@/store/storage/ionicStorage';
import { IonContent, IonItem, IonModal, modalController, useIonRouter } from '@ionic/vue';
import { ComputedRef, computed, onMounted, ref } from 'vue';
import { useI18n } from 'vue-i18n';
//Components
import AButton from "@/components/Base/AButton";
import AppDateTime from '@/components/v2/App/AppDateTime.vue';
import AppInput from '@/components/v2/App/AppInput.vue';
import AppSelect from '@/components/v2/App/AppSelect.vue';
//Composables
import { useCreateSurvey } from '@/composables/useCreateSurvey';
import { useProperties } from '@/composables/Property/useProperties';
import useUser from "@/composables/useUser";
//Helpers
import { useStore } from '@/composables/useTypedStore';
import User from '@/models/user';
import moment from 'moment';
import { useRouter } from 'vue-router';

const props = defineProps({
  property: {
    type: Object,
    required: false,
  },
  templateIdentifier: {
    type: String,
    required: false
  }
})

//Variables
const router = useIonRouter()
const vueRouter = useRouter(); // ion router does not contain the current route
const { t } = useI18n({ useScope: 'global' })
const { user, canCreateUpdateObject } = useUser();
const store = useStore();
const featureFlags = computed(() => user.value?.organisation?.featureFlags);
const { properties } = useProperties()

const fetchedTemplates = ref([] as Array<any>)
const fetchedProperties = ref([] as Array<any>)
const body = ref({
  type: '',
  property: '',
  date: new Date(),
  name: '' as any,
  userId: user.value?.id as any
})
const organisationPrefix: ComputedRef<string> = computed(() => {
  const currentUser: User | null = User.query().first();

  return currentUser ? currentUser.organisationPrefix : "";
});

//Computed Methods
const isButtonActive = computed(() => {
  if(Object.values(body.value).every((item: any) => item !== '')) {
    return true
  } else {
    return false
  }
})

const preparedTemplates = computed(() => {
  const data = fetchedTemplates.value?.map((item: any) => {
    return {
      id: item.id,
      label: item.attributes.vorlage.name.de,
      value: item.attributes.vorlage.uid,
      identifier: item.attributes.vorlage.identifier
    }
  })
  return data
})

const preparedProjects = computed(() => {
  const data = fetchedProperties.value.map((item: any) => {
    return {
      id: item.id,
      label: `${item.name} (${item.externeObjektNr})`,
      value: item.id
    }
  })
  
  return data
})

const routeName = computed(() => vueRouter.currentRoute?.value?.name ?? "");

//Lifecycle Hooks
onMounted(async () => {
  await FetchInitialData()
  populateInitData()
})

//Methods
const FetchInitialData = async () => {
  const cachedTemplates = await getCache('cachedSurveyTemplates')
  fetchedTemplates.value = JSON.parse(cachedTemplates)
  fetchedProperties.value = properties.value
}

async function populateInitData() {
  body.value.userId = user.value?.id
  body.value.name = user.value?.username
  body.value.property = props.property ? props.property.id : ''
  body.value.type = props.templateIdentifier ? await findLatestVersion() : ''
}

async function findLatestVersion() {
  const templatesByIdentifier = preparedTemplates.value.filter(item => item.identifier === props.templateIdentifier)
  const templateVersions = templatesByIdentifier.map(item => item.value)
  const sortedVersions = templateVersions.sort(versionComparator)
  const latestVersions = sortedVersions[0]
  
  return latestVersions
}

const versionComparator = (a: any, b: any) => {
    const getVersionNumbers = (str: string) => str.match(/v(\d+)\.(\d+)\.(\d+)/)?.slice(1).map(Number)

    const numbers1 = getVersionNumbers(a);
    const numbers2 = getVersionNumbers(b);

    if (numbers1?.length !== 3 || numbers2?.length !== 3) {
       return 0;
    }

    const [majorA, minorA, patchA] = numbers1;
    const [majorB, minorB, patchB] = numbers2;

    if (majorA !== majorB) {
        return majorB - majorA
    } else if (minorA !== minorB) {
        return minorB - minorA
    } else {
        return patchB - patchA
    }
};

async function handleCreateProperty() {
  await modalController.dismiss()
  router.navigate('/create-property', 'forward', 'push')
}

async function sendData() {
  store.commit('app/setCreateSurveyData', body.value);
  try {
    await cacheSelectedTemplate()
    const response = await useCreateSurvey().sendDataToBackend(body.value)
    router.navigate(`/ba/${response!.data.data.id}`, 'forward', 'pop')
  } catch(err: any) {
    router.navigate(`/ba/0.${Math.floor(Math.random() * 901) + 100}`, 'forward', 'pop')
  }
  await modalController.dismiss()
}

async function cacheSelectedTemplate() {
  const selectedTemplate = fetchedTemplates.value.filter(item => item.attributes.uid === body.value.type)[0]
  await setCache('selectedSurveyTemplate', JSON.stringify(selectedTemplate))

  const selectedProperty = fetchedProperties.value.filter(item => item.id === body.value.property)[0]
  await setCache('selectedSurveyProperty', JSON.stringify(selectedProperty))
}
</script>

<style lang="scss">
.createSurveyModal {
  height: 100%;

  .createSurveyModalContent {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 32px;

    .formHolder {
      .sectionItem {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
  
        .label, .value {
          width: calc(50% - 8px);
        }
  
        .value {
          background: var(--background100);
  
          ion-item {
            --ion-background-color: transparent;
            --border-color: transparent;
          }
        }
      }

      .createObjectButton {
        display: flex;
        justify-content: flex-end;

        button {
          width: calc(50% - 8px);
        }
      }
    }
  }


  .buttonsHolder {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.dateTimeModal {
  .dateTimeContent {
    display: flex;
    flex-direction: column;

    .dateTimeHolder {
      margin: 0 auto;
    }

    .buttonHolder {
      button {
        width: 100%;
      }
    }
  }
}
</style>
