
<template>
  <ion-page>
    <toolbar :title="t(`${organisationPrefix}hzba.bestandsaufnahmen`)">
      <template #trailing>
        <UserDialogButton />
      </template>
    </toolbar>
    <ion-content>
      <ion-refresher
        slot="fixed"
        @ionRefresh="doRefresh($event)"
      >
        <ion-refresher-content />
      </ion-refresher>

      <div
        style="background: white"
        class="pt-8"
      >
        <div class="container pb-4 px-4 lg:px-4">
          <div class="mt-8 mb-4 flex align-center">
            <h1 class="flex-1 text-2xl md:text-3xl lg:mx-auto">
              {{ t(`${organisationPrefix}hzba.bestandsaufnahmen`) }}
            </h1>
            <div class="flex flex-col items-end">
              <div class="mb-4 flex flex-row items-center">
                <div
                  v-if="canCreateSurvey && featureFlags?.createSurvey"
                  class="createSurveyButtonHolder"
                >
                  <ion-icon
                    slot="icon-only"
                    class="h-14 w-14 cursor-pointer create-survey-button"
                    :icon="addOutline"
                    @click="openAufnahmenModal"
                  />
                </div>
                <div
                  v-if="featureFlags?.survey?.exportxls"
                  class="ml-3"
                >
                  <ExportSurveyAsExcelButton 
                    :is-exporting="isExportingExcel" 
                    @click="exportExcel"
                    @abort="abortExcelExport"
                  />
                </div>
              </div>
              <!-- <div
                v-if="featureFlags?.downloadSurvey"
                class="popoverButtonHolder mb-4"
              >
                <DownloadSurveysPopoverButton
                  @downloadAll="downloadAll"
                  @downloadPlannedForToday="downloadPlannedForToday"
                  @downloadFiltered="downloadFiltered"
                />
              </div> -->
              <div class="flex">
                <ion-label
                  v-if="displayCountLabel"
                  class="text-lg text-right"
                >
                  {{ displayCountLabel }}
                </ion-label>
                <ion-spinner
                  v-if="totalCount === null"
                  class="ml-2 h-5 w-5"
                  color="light"
                />
              </div>
            </div>
          </div>

          <Searchbar
            v-model:search-term="searchTerm"
            v-model:sort-term="sortTerm"
          />
          <Filters
            v-model:selected-filters="selectedFilters"
            :filter-options="filterOptions"
            @update:date-filter="dateFilter = $event"
          />
        </div>
      </div>
      <div class="container pb-4 px-4 lg:px-4">
        <div class="py-4 block md:flex flex-row-reverse text-xl items-center">
          <div class="flex-1 md:text-right">
            <sort-by
              v-model:sort-term="sortTerm"
              :sort-terms="supportedSortTerms"
            />
          </div>
          <div>
            {{ t("hzba.zuletztAktualisiertAm") }}: {{ lastFetch }} <a-button @click="() => doRefresh($event)">
              Refresh
            </a-button>
          </div>
        </div>

        <offline-hint
          class="mb-4"
          style="margin-top: 0;"
        >
          {{ t(`${organisationPrefix}hzba.noInternetConnectionInfo`) }}
        </offline-hint>

        <socket-disconnected-hint
          class="mb-4"
        >
          {{ t(`${organisationPrefix}hzba.noSocketConnectionInfo`) }}
        </socket-disconnected-hint>

        <hzba-list
          v-if="loaded"
          :hzbas="bas"
        />
        <Skeleton v-else />

        <div
          v-if="showLoadMore"
          class="flex flex-row items-center justify-center"
        >
          <AButton
            type="submit"
            :btn-primary="true"
            class="px-16 py-6 mb-2 text-lg"
            data-cy="button-login"
            style="margin-top: 32px !important; margin-left: 0"
            @click="loadMore()"
          >
            {{ t("buttons.loadMore") }}
          </AButton>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import Toolbar from "@/components/Navigation/Toolbar";
import HzbaList from "@/components/hzba/HzbaList.vue";
import DownloadSurveysPopoverButton from "@/components/v2/General/DownloadSurveysPopover.vue";
import { useStore } from "@/composables/useTypedStore";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonImg,
    IonLabel,
    IonPage,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonSpinner,
    modalController,
    onIonViewWillEnter
} from "@ionic/vue";
import { ComputedRef, computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import { generateExcel } from "@/api/generate-excel-api";
import AButton from "@/components/Base/AButton";
import Filters from "@/components/Base/Filters.vue";
import SortBy from "@/components/Base/SortBy.vue";
import OfflineHint from "@/components/Other/OfflineHint.vue";
import SocketDisconnectedHint from "@/components/Other/SocketDisconnectedHint.vue";
import UserDialogButton from "@/components/User/UserDialogButton.vue";
import Searchbar from "@/components/hzba/Base/Searchbar.vue";
import CreateSurveyModal from "@/components/v2/General/CreateSurveyModal.vue";
import ExportSurveyAsExcelButton from "@/components/v2/General/ExportSurveyAsExcelButton.vue";
import { FilterOption, useBestandsaufnahmens } from "@/composables/Bestandsaufnahme/useBestandsaufnahmens";
import { SortTerm, SortTermActive } from "@/composables/Sort/SortTerms";
import useAlert from "@/composables/useAlert";
import useFetchData from "@/composables/useFetchData";
import useUser from "@/composables/useUser";
import Bestandsaufnahme from "@/models/ba/Bestandsaufnahme";
import { HzbaStatusCode, translatedHzbaStatus } from "@/models/ba/interfaces/IBestandsaufnahme";
import BestandsaufnahmeModel from "@/models/ba/models/bestandsaufnahme.model";
import { getLocalMetaData } from "@/models/local-meta-data";
import User from "@/models/user";
import { getPhotoUrl } from "@/utilities/get-media-url";
import { Monitoring } from "@/utilities/monitoring";
import { popoverHelper } from "@/utilities/popover-helper";
import { Canceler } from "axios";
import { swapVerticalOutline, addOutline } from "ionicons/icons";
import moment from "moment";
import { default as QueryString } from 'qs';
import Skeleton from "../components/Skeleton.vue";

export default {
  name: "HzbasPage",
  components: {
    SortBy,
    DownloadSurveysPopoverButton,
    Filters,
    OfflineHint,
    SocketDisconnectedHint,
    Searchbar,
    Skeleton,
    Toolbar,
    IonPage,
    IonContent,
    HzbaList,
    IonButton,
    IonButtons,
    IonImg,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    UserDialogButton,
    AButton,
    IonSpinner,
    IonLabel,
    ExportSurveyAsExcelButton
  },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });
    const store = useStore();
    const { user, canCreateSurvey } = useUser();
    const useData = useFetchData();
    const alert = useAlert();
    const appConfig = computed(() => store.state.app.appConfig)
    const currentProject = computed(() => store.state.user.currentUserProject);
    const featureFlags = computed(() => user.value?.organisation?.featureFlags);
    const { bestandsaufnahmes, loadBestandsaufnahmens, downloadBa } = useBestandsaufnahmens();
    const organisationPrefix: ComputedRef<string> = computed(() => {
      const currentUser: User | null = User.query().first();

      return currentUser ? currentUser.organisationPrefix : "";
    });

    const searchTerm = ref<string | undefined>("");
    const sortTerm = ref<SortTermActive | undefined>(BestandsaufnahmeModel.getters("sortBy"));

    const isExportingExcel = ref(false);
    const abortExcelExportFunction = ref<Canceler | null>(null);

    /**
     * Options to filter bestandsaufnahmes
     * filterOptions were moved here from useBestandsaufnahmens.ts due to I18n not useable outside setup
     */
     const filterOptions: FilterOption[] = [
        { name: translatedHzbaStatus(HzbaStatusCode.OFFLINE, t), id: HzbaStatusCode.OFFLINE },
        { name: translatedHzbaStatus(HzbaStatusCode.ANGELEGT, t), id: HzbaStatusCode.ANGELEGT },
        { name: translatedHzbaStatus(HzbaStatusCode.GEPLANT, t), id: HzbaStatusCode.GEPLANT },
        { name: translatedHzbaStatus(HzbaStatusCode.IN_DURCHFUEHRUNG, t), id: HzbaStatusCode.IN_DURCHFUEHRUNG },
        { name: translatedHzbaStatus(HzbaStatusCode.ABGESCHLOSSEN, t), id: HzbaStatusCode.ABGESCHLOSSEN },
        { name: translatedHzbaStatus(HzbaStatusCode.FREIGEGEBEN, t), id: HzbaStatusCode.FREIGEGEBEN },
        { name: translatedHzbaStatus(HzbaStatusCode.ARCHIVIERT, t), id: HzbaStatusCode.ARCHIVIERT},
        { name: translatedHzbaStatus(HzbaStatusCode.RESET, t), id: HzbaStatusCode.RESET, resetOtherFilters: true, resetSelfWhenOthersActive: true, onlyShowWhenOthersActive: true }

    ]

    /**
     * Options to sort.
     */
     const supportedSortTerms: SortTerm[] = [
        { label: t('hzba.vorschau.sortLabels.sortBegehung'), fieldName: 'begehungsdatum' },
        { label: t('hzba.vorschau.sortLabels.sortStatus'), fieldName: 'status' },
        { label: t('hzba.vorschau.sortLabels.sortLeitung'), fieldName: 'stadt', subObject: 'immobilie' },
        { label: t('hzba.vorschau.sortLabels.sortMast'), fieldName: 'name', subObject: 'immobilie' },
        { label: t('hzba.vorschau.sortLabels.sortObjekt'), fieldName: 'externeObjektNr', subObject: 'immobilie' },
        { label: 'Bewertung', fieldName: 'malusColor' }
    ];

    onIonViewWillEnter(
      () => { 
        store.commit("app/showBottomNavigation"); 
        // loadBestandsaufnahmens();
      }
    );

    const bas = computed(() => {
      const value = bestandsaufnahmes.value.map((el) => new Bestandsaufnahme(el.copyJson()));
      return value
    });
    const loaded = computed(() => BestandsaufnahmeModel.getters("loaded"));

    const lastFetch = computed(() => {
      const date = moment(getLocalMetaData("lastBackendFetch"));
      return `${date.format("DD.MM.YYYY")} / ${date.format("HH:mm:ss")} Uhr`;
    });

    const isNetworkConnected = computed(() => store.state.app.networkConnected);

    const retrievedCount = computed(() => store.state.entities.bestandsaufnahmes.searchResultsCount); 
    const totalCount = computed(() => store.state.entities.bestandsaufnahmes.totalSearchResultsCount);
    
    const displayCountLabel = computed(() => {
      if (totalCount.value === 0) {
        return t("hzba.searchResults.noResults");
      } else if (totalCount.value === 1) {
        return t("hzba.searchResults.resultsCount.one");
      } else if (retrievedCount.value === null || totalCount.value === null) {
        return t("hzba.searchResults.loadingLabel");
      } else if (BestandsaufnahmeModel.getters("page") >= BestandsaufnahmeModel.getters("pageCount")) {
        return t("hzba.searchResults.resultsCount.other", { count: totalCount.value });
      } else {
        return t("hzba.searchResults.resultsCountOf", { retrieved: retrievedCount.value, total: totalCount.value });
      } 
    });

    const selectedFilters = ref([]);
    const dateFilter = ref<string | string[]>("");

    const resetSearch = () => {
      BestandsaufnahmeModel.commit(state => state.page = 0);
      BestandsaufnahmeModel.deleteAll();
      loadBestandsaufnahmens();
    };

    watch([searchTerm, sortTerm, selectedFilters, dateFilter], () => {
      BestandsaufnahmeModel.dispatch("setSearchTerm", searchTerm.value);
      BestandsaufnahmeModel.dispatch("setSortBy", sortTerm.value);
      BestandsaufnahmeModel.dispatch("setStateFilter", selectedFilters);
      BestandsaufnahmeModel.dispatch("setDateFilter", dateFilter.value);
      resetSearch();
    });
  
    watch(isNetworkConnected, (connected) => {
      if (connected) {
        resetSearch();
      }
    });

    watch(bestandsaufnahmes, (results: BestandsaufnahmeModel[]) => {
        const isOffline = store.getters["app/isOffline"];
        const stateFilters: FilterOption[] = BestandsaufnahmeModel.getters("stateFilter");

        // online case is handled in composable after server call occurs
        if(isOffline || stateFilters.find(filter => filter.id === HzbaStatusCode.OFFLINE)) {
            BestandsaufnahmeModel.commit((state) => {
                state.searchResultsCount = results.length;
                state.totalSearchResultsCount = results.length;
            });
        }
    });

    const showLoadMore = computed(() => {
      return (
        BestandsaufnahmeModel.getters("pageCount") &&
        BestandsaufnahmeModel.getters("page") <
        BestandsaufnahmeModel.getters("pageCount")
      );
    });

    const loadMore = () => {
      loadBestandsaufnahmens();
    };

    const exportExcel = () => {
 
      if (user?.value?.organisation?.id && currentProject?.value?.id) {
        isExportingExcel.value = true;
        const { call, abort } = generateExcel(
          user.value.organisation.id,
          currentProject.value.id,
          t(`${organisationPrefix.value}hzba.bestandsaufnahmen`),
          locale.value
        );

        call.finally(() => {
          isExportingExcel.value = false;
        });

        abortExcelExportFunction.value = abort;
      } else {
        Monitoring.withScope((scope) => {
          scope.setContext("export info", { projectId: currentProject?.value?.id, organisationId: user?.value?.organisation?.id });
          Monitoring.error("Cannot export excel-sheet, no organisation or project id found for user");
        });
      }
    };

    const abortExcelExport = () => {
      if (abortExcelExportFunction.value) {
        abortExcelExportFunction.value();
      }
    };

    return {
      appConfig,
      downloadBa,
      alert,
      bas,
      user,
      getPhotoUrl,
      openPopover: popoverHelper,
      loaded,
      doRefresh: useData.doRefresh,
      searchTerm,
      supportedSortTerms,
      sortTerm,
      lastFetch,
      swapVerticalOutline,
      t,
      filterOptions,
      selectedFilters,
      dateFilter,
      showLoadMore,
      loadMore,
      bestandsaufnahmes,
      loadBestandsaufnahmens,
      featureFlags,
      canCreateSurvey,
      organisationPrefix,
      displayCountLabel,
      totalCount,
      exportExcel,
      isExportingExcel,
      abortExcelExport,
      addOutline
    };
  },

  methods: {
    async openAufnahmenModal() {
      const modal = await modalController.create({
        component: CreateSurveyModal,
        cssClass: 'v2Modal',
        canDismiss: true,
        //@ts-ignore
        presentingElement: this.$root.$refs.ionRouter.ionRouterOutlet
      });
      return modal.present();
    },

    async loadAllBestandaufnames() {
      const apiQuery: any = {
        pagination: {
          page: 1,
          pageSize: 10000000000
        }
      };

      const query = QueryString.stringify(apiQuery)
      await this.loadBestandsaufnahmens(query)
    },

    // async downloadAll() {
    //   await this.loadAllBestandaufnames()

    //   const filteredBas = this.bas.filter((item: any) => item.immobilie)

    //   try {
    //     for(let i=0; i<filteredBas.length;i++) {
    //       await this.downloadBa(filteredBas[i])
    //     }
    //   } catch(err: any) {
    //     console.log(err)
    //   }
    // },

    // async downloadPlannedForToday() {
    //   const today = new Date()
    //   const filteredBas = this.bas.filter(
    //     (item: any) =>
    //       item.immobilie &&
    //       today.setHours(0,0,0,0) === new Date(item.begehungsdatum).setHours(0,0,0,0)
    //     )

    //   if(filteredBas.length === 0) {
    //     this.alert.show(
    //       this.t("downloadSurveysPopover.noSurveysPlannedForToday"),
    //     );
    //   } else {
    //     try {
    //       for(let i=0; i<filteredBas.length;i++) {
    //         await this.downloadBa(filteredBas[i])
    //       }
    //     } catch(err: any) {
    //       console.log(err)
    //     }
    //   }
    // },

    // async downloadFiltered() {
    //   const filteredBas = this.bas.filter((item: any) => item.immobilie)

    //   try {
    //     for(let i=0; i<filteredBas.length;i++) {
    //       await this.downloadBa(filteredBas[i])
    //     }
    //   } catch(err: any) {
    //     console.log(err)
    //   }
    // }
  }
};
</script>

<style scoped>
.ion-searchbar {
  --background: var(--white100) !important;
  --background-color: var(--white100) !important;
  --border-radius: 8px;
  --box-shadow: 1px 1px 1px rgb(206, 206, 206);
  padding: 0px !important;
}

.create-survey-button {
  border-radius: 50%;
  background-color: var(--primary);
  color: white;
}
</style>
