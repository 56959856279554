import { toastController } from "@ionic/vue";
import { useI18n } from "vue-i18n";

export default function useToasts() {

    const { t } = useI18n({ useScope: 'global' });

    async function presentToast(message: string, duration = 2000) {
        const toast = await toastController
            .create({
                message,
                duration,
                cssClass: "toastStyle"
            });

        // according to https://github.com/ionic-team/ionic-framework/issues/22704
        const active = await toastController.getTop();

        if (active) {
            await active.dismiss();
        }

        return toast.present();
    }

    function changesSavedLocally() {
        return presentToast(t('toasts.savedChangesLocally'));
    }

    function updateErrorBestandsaufnahme(id: number, title: string) {
        return presentToast(t('toasts.bestandsaufnahmeUpdatedError', { id, frage: title }), 5000);
    }

    function socketConnected() {
        return presentToast(t('toasts.socketConnected'), 8000);
    }

    function updateErrorDateToast() {
        return presentToast(t("hzba.datum.updateFailed"), 5000);
    }

    function deleteDocumentErrorToast() {
        return presentToast(t("toasts.deleteDocumentError"), 5000);
    }

    return {
        changesSavedLocally,
        updateErrorBestandsaufnahme,
        socketConnected,
        updateErrorDateToast,
        deleteDocumentErrorToast
    };
}