import { modalController } from "@ionic/vue";
import { store } from '@/store/store';
import { logger } from "./logging";
import {Fragenblock} from "@/models/ba/Fragenblock";
import _ from "lodash";
import { Monitoring } from "./monitoring";


export const openBaseModal = async (name: any, props: any, callback?: any) => {

    let comp;
    let enableSoftclose = false;

    switch (name) {

        case 'documents': comp = require('@/components/Base/DocumentsGalleryModal').default; enableSoftclose = true; break;
        case 'pdf.view': comp = require('@/components/Base/PdfModal').default; enableSoftclose = true; break;
        case 'gallery.pdf': comp = require('@/components/Base/PdfGalleryModal').default; enableSoftclose = true; break;
        // Do not import the components as this might lead to recursive imports, require them instead
        case 'fragenblock': comp = require('@/components/FragenblockModal.vue').default; break;

        case 'gallery': comp = require("@/components/Base/ImageGalleryModal.vue").default; enableSoftclose = true; break;
        case 'gallery.iframe': comp = require("@/components/Base/IframeGalleryModal.vue").default; enableSoftclose = true; break;
        case 'gallery.iframes': comp = require("@/components/Base/IframesGalleryModal.vue").default; enableSoftclose = true; break;

        case 'maengel.uebersicht': comp = require("@/components/hzba/MaengelUebersichtModal.vue").default; enableSoftclose = true; break;
        case 'maengel.immobilie.uebersicht': comp = require("@/components/properties/CSVMangelliste.vue").default; enableSoftclose = true; break;

        case 'signandsubmit': comp = require("@/components/hzba/SignAndSubmitModal.vue").default; enableSoftclose = true; break;
        case 'conclusion': comp = require("@/components/hzba/Conclusion.vue").default; enableSoftclose = true; break;

        case 'upload': comp = require("@/components/hzba/HzbaUploadModal.vue").default; enableSoftclose = true; break;

        default: comp = undefined;
    }

    if (!comp) {
        Monitoring.withScope((scope) => {
            scope.setContext("Modal info", { name, props });
            Monitoring.error("Modal name not found");
        });
        return;
    }

    const modal = await modalController.create({
        component: comp,
        cssClass: "modal-fullscreen",
        componentProps: props,
        backdropDismiss: enableSoftclose
    });
    modal.onDidDismiss().then((res) => {
        if (res.data) {
            callback(res.data);
        } else {
            // aborted
        }
    })
    await modal.present();
    return modal;
};

export const openHzbaModal = _.debounce((name: any, props: any, modalCallback: (data: Fragenblock) => any) => {

    if ((props.isSubmodal && store.state.currentHzba.subModalHzba) || (!props.isSubmodal && store.state.currentHzba.modalHzba)) {
        console.log("Double open same modal is not supported.")
        return;
    }

    const callback = (data: any) => {
        modalCallback && modalCallback(data);
        logger.info(`Saved Modal, modal-name: ${name}, data: ${data}`);
        store.dispatch("currentHzba/saveHzbaLocally");
    };

    logger.info(`Opened Modal, modal-name: ${name}, data: ${props}`);
    openBaseModal(name, props, callback);
}, 300)
